<template>
  <div>
    <div v-if="activegroup">
      <div class="mb-2 border-b border-gray-700">
        <div
          class="p-1 text-xs bg-black cursor-pointer hover:bg-gray-900"
          @click.prevent="activegroup = null"
        >
          <div class="mx-2 text-blue-600 select-none ">
            <div class="flex">
              <div class="flex-auto">
                <span class="pr-1">
                  <fa-icon
                    icon="caret-left"
                    size="lg"
                  />
                </span>
                Back to all mappings
              </div>
            </div>
          </div>
        </div>
        <BlockHeader :label="activegroup.name" />
        <MappingGroupsBlock
          :group="activegroup"
          :mode="'full'"
          :meshes="objects.meshes"
          @highlight="onHighlightGroup"
          @view="onViewGroup"
          @delete="onDeleteGroup"
        />
      </div>
    </div>
    <div v-else>
      <div class="border-b border-gray-700">
        <BlockHeader label="Single Mesh + UV Maps" />
        <MappingSingleBlock
          :meshes="objects.meshes"
          :highlighted="highlightedMeshes"
          @highlight="onViewSingle"
        />
      </div>

      <div class="mb-2 bg-gray-700">
        <div class="flex flex-wrap">
          <div class="w-full">
            <InputWrapper
              :block="{ inverted: true }"
              :row="newGroupFromSelectionBtn"
              :rowkey="'mappingadd'"
              @change="onAddGroup"
            />
          </div>
        </div>
      </div>

      <div
        v-if="groups.length > 0"
        class="mb-2 border-b border-gray-700"
      >
        <BlockHeader :label="'Mapping Groups (' + groups.length + ')'" />

        <div
          v-for="group in groups"
          :key="group.id"
        >
          <MappingGroupsBlock
            :group="group"
            :highlighted-group="highlightedMeshGroup"
            @highlight="onHighlightGroup"
            @view="onViewGroup"
            @delete="onDeleteGroup"
          />
        </div>
      </div>
    </div>
  </div>

  </div>
</template>
<script>
import { generateUuid } from '@utils/misc'
const BlockHeader = () => import('@components/editor/scene/blocks/BlockHeader')
const MappingSingleBlock = () => import('@components/editor/scene/blocks/MappingSingleBlock')
const MappingGroupsBlock = () => import('@components/editor/scene/blocks/MappingGroupsBlock')
const InputWrapper = () => import('@components/editor/module/inputs/InputWrapper')

export default {
  name: 'MappingsPanel',

  components: {
    BlockHeader,
    InputWrapper,
    MappingSingleBlock,
    MappingGroupsBlock
  },

  props: ['asset', 'settings', 'objects'],

  data () {
    return {
      viewer: null,
      meshHelper: null,

      groups: [],
      activegroup: null
    }
  },

  computed: {

    highlightedMeshGroup () {
      if (this.meshHelper !== null) return this.meshHelper.highlightedGroup
      return false
    },

    highlightedMeshes () {
      if (this.meshHelper !== null) return this.meshHelper.highlighted
      return []
    },

    hasHighlightedMeshes () {
      return this.highlightedMeshes.length > 0
    },

    newGroupFromSelectionBtn () {
      let title = '+ New Empty Group'
      if (this.hasHighlightedMeshes) {
        title = '+ Save as new group (' + this.highlightedMeshes.length + ' meshes)'
      }

      return {
        type: 'button',
        value: title,
        label: 'Create',
        fullwidth: true
      }
    }

  },

  mounted () {
    this.viewer = window.previzviewer
    this.meshHelper = this.viewer.core.gui.meshHelper

    this.extractGroups()
  },

  methods: {

    extractGroups () {
      if (this.settings !== null) {
        Object.keys(this.settings).forEach(function (key, index) {
          if (key.startsWith('mappinggroup.')) {
            let parts = key.split('.')
            let id = parts[1]

            if (parts.length === 3) {
              if (key.endsWith('.name')) {
                this.setOrNewGroup(id, 'name', this.settings[key])
              }

              if (key.endsWith('.selected')) {
                let objs = this.settings[key]
                if (objs !== null && objs !== false) {
                  this.setOrNewGroup(id, 'selected', objs.split(','))
                }
              }
            }
          }
        }.bind(this))
      }
    },

    setOrNewGroup (newid, key, value) {
      let existing = this.groups.find(row => row.id === newid)

      if (existing === undefined) {
        // New group
        let group = {
          id: newid,
          name: 'Group',
          selected: []
        }

        group[key] = value
        this.groups.push(group)
      } else {
        existing[key] = value
      }
    },

    onDeleteGroup (groupId) {
      let existingIndex = this.groups.findIndex(row => row.id === groupId)

      this.groups.splice(existingIndex, 1)

      // Persist this change.
      this.storeRemoveGroup(groupId)
    },

    onAddGroup () {
      let id = generateUuid()
      let c = this.groups.length + 1
      let group = {
        name: 'Group ' + c,
        id: id,
        selected: this.highlightedMeshes
      }

      this.storeNewGroup(group)
      this.groups.push(group)
    },

    storeRemoveGroup (groupId) {
      let path = 'mappinggroup.' + groupId + '.name'
      let value = true

      this.updateGroupSettings(path, value)
    },

    storeNewGroup (group) {
      let path = 'mappinggroup.' + group.id + '.name'
      let value = group.name

      this.updateGroupSettings(path, value)

      let pathObjs = 'mappinggroup.' + group.id + '.selected'
      let valueObjs = group.selected.join(',')

      this.updateGroupSettings(pathObjs, valueObjs)
    },

    updateGroupSettings (path, value) {
      this.$store.dispatch('assets/updateAssetSettings', {
        id: this.asset.id,
        path: path,
        value: value
      })
    },

    onViewSingle (meshname, uv) {
      if (this.highlightedMeshes.indexOf(meshname) > -1) {
        this.meshHelper.unhighlightMesh(meshname)
      } else {
        this.meshHelper.highlightMesh(meshname)
      }
    },

    onHighlightGroup (id) {
      let group = this.groups.find(group => group.id === id)
      this.meshHelper.highlightMeshGroup(group.name, group.selected)
    },

    onViewGroup (id) {
      this.activegroup = this.groups.find(group => group.id === id)
    },

    viewSingleEdit (mesh) {
      this.singlemesh = mesh
    },

    getSceneObj (meshname) {
      if (this.viewer === null) return null
      let obj = this.viewer.core.model.scene.getObjectByName(meshname)
      return obj
    }
  }

}

</script>
